import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './style/common.css'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'mobx-react'
import sideBarStore from './stores/SideBarStore'
const rootStore = {
  sideBarStore: sideBarStore,
}
createRoot(document.getElementById('root')).render(
  <Provider rootStore={rootStore}>
    <App />
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { action, computed, makeObservable, observable } from 'mobx'

class SideBarStore {
  constructor() {
    makeObservable(this)
  }
  @observable
  _sidebarShow = true

  @computed
  get getSideBarShow() {
    return this._sidebarShow
  }

  @action
  changeSideBarShow(val) {
    this._sidebarShow = val
  }

  @observable
  _unFoldAble = false

  @computed
  get getFoldAbleShow() {
    return this._unFoldAble
  }

  @action
  changeFoldAbleShow(val) {
    this._unFoldAble = val
  }
}
export default new SideBarStore()
